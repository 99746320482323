<template>
  <div class="management-departament">
    <template>
      <validation-observer ref="form">
        <b-card class="mx-2">
          <template #header>
            <b-card-title class="title-uwu text-primary">
              <strong>
                <feather-icon icon="SettingsIcon" size="18" class="mr-1" />
                DEPARTMENT INFORMATION
              </strong>
            </b-card-title>
          </template>
          <template #default>
            <b-row class="pr-1">
              <b-col md="8" class="pb-0">
                <b-row>
                  <b-col md="6" class="pb-1">
                    <validation-provider
                      v-slot="{ errors }"
                      name="type"
                      rules="required"
                    >
                      <b-input-group>
                        <b-input-group-prepend style="width: 30%">
                          <b-input-group-text
                            class="title_lable"
                            style="width: 100%"
                          >
                            <div
                              class="d-flex justify-content-between align-items-center w-100"
                            >
                              <span> Type </span>
                              <feather-icon
                                icon="PlusCircleIcon"
                                class="cursor-pointer text-success"
                                size="15"
                                @click="showCreateDepartmentTypeModal"
                              />
                            </div>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <v-select
                          id="id"
                          v-model="departament.departamentTypeId"
                          :options="departamentTypes"
                          :reduce="(val) => val.id"
                          label="name"
                          style="width: 70%"
                          placeholder="Select a Type ..."
                          :class="errors[0] ? 'border-danger rounded' : ''"
                          :clearable="false"
                        />
                      </b-input-group>
                      <small v-if="errors[0]" class="text-danger">
                        {{ errors[0] }}
                      </small>
                    </validation-provider>
                  </b-col>
                  <b-col md="6" class="pb-1">
                    <validation-provider
                      v-slot="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <b-input-group>
                        <b-input-group-prepend style="width: 30%">
                          <b-input-group-text
                            class="title_lable"
                            style="width: 100%"
                          >
                            Name
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <v-select
                          id="id"
                          v-model="departament.moduleId"
                          :options="nameModules"
                          :reduce="(val) => val.id"
                          label="name"
                          style="width: 70%"
                          placeholder="Select a Type ..."
                          :class="errors[0] ? 'border-danger rounded' : ''"
                          :clearable="false"
                        >
                          <template v-slot:option="option">
                            <img
                              :src="`/assets/${option.icons}`"
                              alt="Logo"
                              width="24"
                              height="24"
                              class="mr-1"
                            />
                            {{ option.name }}
                          </template>
                          <template #selected-option="option">
                            <img
                              :src="`/assets/${option.icons}`"
                              alt="Logo"
                              width="24"
                              height="24"
                              class="mr-1"
                            />
                            {{ option.name }}
                          </template>
                        </v-select>
                      </b-input-group>
                      <small v-if="errors[0]" class="text-danger">
                        {{ errors[0] }}
                      </small>
                    </validation-provider>
                  </b-col>
                  <b-col md="12" class="pb-1">
                    <validation-provider
                      v-slot="{ errors }"
                      name="departament_type"
                      rules="required"
                    >
                      <b-input-group>
                        <b-input-group-prepend style="width: 15%">
                          <b-input-group-text
                            class="title_lable"
                            style="width: 100%"
                          >
                            Company
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <v-select
                          id="id"
                          v-model="departament.associatedCompany"
                          :options="associatedCompanyOptions"
                          :reduce="(val) => val.id"
                          label="name"
                          style="width: 70%"
                          placeholder="Select a Company ..."
                          :class="errors[0] ? 'border-danger rounded' : ''"
                          :clearable="false"
                        >
                          <template #selected-option="{ name }">
                            <span id="tooltip-button-not-interactive">
                              {{ name }}</span
                            >
                            <b-tooltip
                              v-if="name.length >= 22"
                              target="tooltip-button-not-interactive"
                              noninteractive
                              >{{ name }}</b-tooltip
                            >
                          </template>
                          <template #option="{ name }">
                            <span> {{ name }}</span>
                          </template>
                        </v-select>
                      </b-input-group>
                      <small v-if="errors[0]" class="text-danger">
                        {{ errors[0] }}
                      </small>
                    </validation-provider>
                  </b-col>
                  <b-col md="6" class="pb-1">
                    <validation-provider
                      v-slot="{ errors }"
                      name="dba"
                      rules="required"
                    >
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text
                            class="title_lable"
                            style="width: 100%"
                            >DBA
                          </b-input-group-text>
                        </template>
                        <b-form-input
                          v-model="departament.dba"
                          :class="errors[0] ? 'border-danger' : ' '"
                          style="width: 70%"
                        />
                      </b-input-group>
                      <small v-if="errors.length > 0" class="text-danger">
                        {{ errors[0] }}
                      </small>
                    </validation-provider>
                  </b-col>

                  <b-col md="6" class="pb-1">
                    <validation-provider
                      v-slot="{ errors }"
                      name="man_bank_account"
                      rules="required"
                    >
                      <b-input-group>
                        <b-input-group-prepend style="width: 30%">
                          <b-input-group-text
                            style="width: 100%"
                            class="title_lable"
                          >
                            Bank Name
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <v-select
                          v-model="departament.manBankAccountId"
                          :options="manBankAccountOptions"
                          :reduce="(val) => val.id"
                          label="name"
                          style="width: 70%"
                          placeholder="Select a Bank ..."
                          :class="errors[0] ? 'border-danger rounded' : ''"
                          :clearable="false"
                        />
                      </b-input-group>
                      <small v-if="errors[0]" class="text-danger">
                        {{ errors[0] }}
                      </small>
                    </validation-provider>
                  </b-col>
                  <b-col md="6" class="pb-1">
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text
                          class="title_lable"
                          style="width: 100%"
                          >Account
                        </b-input-group-text>
                      </template>
                      <b-form-input
                        v-model="accountNumber"
                        style="width: 70%"
                        disabled
                      />
                    </b-input-group>
                  </b-col>
                  <b-col md="6" class="pb-1">
                    <validation-provider
                      v-slot="{ errors }"
                      name="phone"
                      rules="required"
                    >
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text
                            class="title_lable"
                            style="width: 100%"
                            >Phone
                          </b-input-group-text>
                        </template>
                        <b-form-input
                          v-model="departament.mainTelephone"
                          :class="errors[0] ? 'border-danger' : ' '"
                          v-mask="'(###)-###-####'"
                          style="width: 70%"
                        />
                      </b-input-group>
                      <small v-if="errors.length > 0" class="text-danger">
                        {{ errors[0] }}
                      </small>
                    </validation-provider>
                  </b-col>
                  <b-col md="6" class="pb-1">
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text
                          class="title_lable"
                          style="width: 100%"
                          >FAX
                        </b-input-group-text>
                      </template>
                      <b-form-input
                        v-model="departament.fax"
                        style="width: 70%"
                      />
                    </b-input-group>
                  </b-col>
                  <b-col md="12" class="pb-1">
                    <validation-provider
                      v-slot="{ errors }"
                      name="email"
                      rules="required|email"
                    >
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text
                            class="title_lable"
                            style="width: 100%"
                            >Email
                          </b-input-group-text>
                        </template>
                        <b-form-input
                          v-model="departament.emailAddress"
                          :class="errors[0] ? 'border-danger' : ' '"
                          style="width: 70%"
                        />
                      </b-input-group>
                      <small v-if="errors.length > 0" class="text-danger">
                        {{ errors[0] }}
                      </small>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>

              <b-col md="4" class="px-0 pr-0">
                <div class="selectLogo" style="border-radius: 5px">
                  <input
                    type="file"
                    ref="file"
                    class="d-none"
                    @input="fileFill"
                    accept="image/*"
                  />
                  <img
                    class="pb-1"
                    :src="
                      !departament.logo && !previewImage
                        ? 'https://programacion.net/files/article/20160819020822_image-not-found.png'
                        : previewImage
                        ? previewImage
                        : departament.logo
                        ? departament.logo
                        : null
                    "
                    @click="$refs.file.click()"
                    alt="Logo"
                    width="100%"
                    height="100%"
                    style="
                      height: 250px;
                      object-fit: contain;
                      cursor: pointer;
                      border-radius: 5px;
                    "
                  />
                  <div class="message_logo d-flex justify-content-between">
                    <div>
                      <span>...Upload logo</span>
                    </div>
                    <div v-if="departament.logo || previewImage">
                      <feather-icon
                        icon="TrashIcon"
                        size="25"
                        class="mr-1 cursor-pointer text-danger"
                        @click="deleteLogo"
                      />
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row> </b-row>
            <show-dynamic-components
              v-if="showComponent"
              :slug="'department-information'"
              :id="2"
              :group_id="custom_form_group"
              :moduleId="moduleId"
              ref="departmentInformationsCustomForms"
            />
          </template>
        </b-card>
        <b-card class="mx-2">
          <template #header>
            <b-card-title class="title-uwu text-success">
              <strong>
                <feather-icon icon="MapIcon" size="18" class="mr-1" />
                DEPARTMENT ADDRESS
              </strong>
            </b-card-title>
          </template>
          <template #default>
            <b-row>
              <b-col md="6" class="pb-1">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text class="title_lable" style="width: 100%"
                      >Address
                    </b-input-group-text>
                  </template>
                  <vue-google-autocomplete
                    id="address_1"
                    ref="address_1"
                    v-model="departament.physicalAddress"
                    classname=" input-form form-control fond-white"
                    placeholder="Please type your address"
                    :country="['US', 'PE']"
                    style="height: 35px !important; width: 70%"
                    @placechanged="getAddressData1"
                    @change="captureChanged1"
                  />
                </b-input-group>
              </b-col>
              <b-col md="6" class="pb-1">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text class="title_lable" style="width: 100%"
                      >City
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    v-model="departament.physicalCity"
                    style="width: 70%"
                    disabled
                  />
                </b-input-group>
              </b-col>
              <b-col md="6" class="pb-1">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text class="title_lable" style="width: 100%"
                      >State
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    v-model="departament.physicalState"
                    style="width: 70%"
                    disabled
                  />
                </b-input-group>
              </b-col>
              <b-col md="6" class="pb-1">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text class="title_lable" style="width: 100%"
                      >Zip Code
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    v-model="departament.physicalZipCode"
                    style="width: 70%"
                    disabled
                  />
                </b-input-group>
              </b-col>
              <b-col md="12" class="pb-1">
                <b-input-group>
                  <b-form-checkbox v-model="departament.addMaillingAddress" />
                  <span class="ml-1">Add mailling address?</span>
                </b-input-group>
              </b-col>
              <b-col md="6" class="pb-1" v-if="departament.addMaillingAddress">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text class="title_lable" style="width: 100%"
                      >Address
                    </b-input-group-text>
                  </template>
                  <vue-google-autocomplete
                    id="address_2"
                    ref="address_2"
                    v-model="departament.mailingAddress"
                    classname=" input-form form-control fond-white"
                    placeholder="Please type your address"
                    :country="['US', 'PE']"
                    style="height: 35px !important; width: 70%"
                    @placechanged="getAddressData2"
                    @change="captureChanged2"
                  />
                </b-input-group>
              </b-col>
              <b-col md="6" class="pb-1" v-if="departament.addMaillingAddress">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text class="title_lable" style="width: 100%"
                      >City
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    v-model="departament.mailingCity"
                    style="width: 70%"
                    disabled
                  />
                </b-input-group>
              </b-col>
              <b-col md="6" class="pb-1" v-if="departament.addMaillingAddress">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text class="title_lable" style="width: 100%"
                      >State
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    v-model="departament.mailingState"
                    style="width: 70%"
                    disabled
                  />
                </b-input-group>
              </b-col>
              <b-col md="6" class="pb-1" v-if="departament.addMaillingAddress">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text class="title_lable" style="width: 100%"
                      >Zip Code
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    v-model="departament.mailingZipCode"
                    style="width: 70%"
                    disabled
                  />
                </b-input-group>
              </b-col>
            </b-row>
          </template>
        </b-card>
        <b-card class="mx-2">
          <template #header>
            <b-card-title class="title-uwu text-info">
              <strong>
                <feather-icon icon="MessageCircleIcon" size="18" class="mr-1" />
                SOCIAL MEDIA
              </strong>
            </b-card-title>
          </template>
          <template #default>
            <b-row>
              <!-- <b-col md="12" class="pb-1">
                <b-input-group>
                  <b-form-checkbox v-model="departament.addSocialMedia" />
                  <span class="ml-1">Add Social Media?</span>
                </b-input-group>
              </b-col> -->
              {{ departament.socialMedia.others }}
              <b-col md="12">
                <b-row
                  v-for="(item, index) in departament.socialMedia"
                  :key="index"
                >
                  <b-col md="6" class="pb-1" :id="'col-web' + index">
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text
                          class="title_lable"
                          style="width: 100%"
                          >Web Page
                        </b-input-group-text>
                      </template>
                      <b-form-input
                        v-model="item.web_page"
                        style="width: 70%"
                      />
                    </b-input-group>
                  </b-col>
                  <b-col md="6" class="pb-1" :id="'col-web' + index">
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text
                          class="title_lable"
                          style="width: 100%"
                          >Youtube Channel
                        </b-input-group-text>
                      </template>
                      <b-form-input
                        v-model="item.youtube_channel"
                        style="width: 70%"
                      />
                    </b-input-group>
                  </b-col>
                  <b-col md="6" class="pb-1" :id="'col-web' + index">
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text
                          class="title_lable"
                          style="width: 100%"
                          >Facebook
                        </b-input-group-text>
                      </template>
                      <b-form-input
                        v-model="item.facebook"
                        style="width: 70%"
                      />
                    </b-input-group>
                  </b-col>
                  <b-col md="6" class="pb-1" :id="'col-web' + index">
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text
                          class="title_lable"
                          style="width: 100%"
                          >Instagram
                        </b-input-group-text>
                      </template>
                      <b-form-input
                        v-model="item.instagram"
                        style="width: 70%"
                      />
                    </b-input-group>
                  </b-col>
                  <b-col md="6" class="pb-1" :id="'col-web' + index">
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text
                          class="title_lable"
                          style="width: 100%"
                          >Tiktok
                        </b-input-group-text>
                      </template>
                      <b-form-input v-model="item.tiktok" style="width: 70%" />
                    </b-input-group>
                  </b-col>
                  <b-col
                    md="6"
                    class="pb-1"
                    :id="'col-other' + index"
                    v-for="(other, index) in item.others"
                    :key="index"
                  >
                    <b-input-group>
                      <template #prepend>
                        <b-form-input
                          v-model="other.name"
                          @blur="$set(other, 'editing', true)"
                          v-if="!other.editing"
                        >
                        </b-form-input>
                        <b-input-group-text
                          class="title_lable"
                          style="width: 100%"
                          v-else
                          ><div
                            class="d-flex justify-content-between align-items-center w-100"
                          >
                            <span @dblclick="$set(other, 'editing', false)">
                              {{ other.name }}
                            </span>
                            <feather-icon
                              icon="XCircleIcon"
                              class="cursor-pointer text-danger"
                              size="15"
                              @click="deleteOther(index)"
                            />
                          </div>
                        </b-input-group-text>
                      </template>
                      <b-form-input v-model="other.value" style="width: 70%" />
                    </b-input-group>
                  </b-col>
                  <b-col
                    md="6"
                    class="d-flex align-items-center mb-1 text-left"
                  >
                    <feather-icon
                      class="text-success cursor-pointer blink"
                      icon="PlusCircleIcon"
                      size="25"
                      @click="addOtherSocialMedia()"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <show-dynamic-components
              v-if="showComponent"
              :slug="'department-social-media'"
              :id="2"
              :group_id="custom_form_group"
              :moduleId="moduleId"
              ref="departmentSocialMediaCustomForms"
            />
          </template>
        </b-card>
        <b-card class="mx-2">
          <template #header>
            <b-card-title class="title-uwu text-warning">
              <strong>
                <feather-icon icon="UserIcon" size="18" class="mr-1" />
                MEMBERS
              </strong>
            </b-card-title>
          </template>
          <template #default>
            <b-row>
              <b-col md="12">
                <b-row
                  v-for="(item, index) in departament.members"
                  :key="index"
                  class="py-2"
                >
                  <b-col v-if="!item.employee" class="text-center">
                    <h4 class="mb-1">
                      <feather-icon
                        icon="AlertTriangleIcon"
                        size="30"
                        class="text-warning"
                      />
                      <span
                        class="ml-1 font-weight-bold"
                        style="font-weight: 700 !important"
                        >{{ item.module_name }} has no organization chart</span
                      >
                    </h4>
                  </b-col>
                  <b-col md="12" class="pb-1" v-if="item.employee">
                    <h4 class="mb-1">
                      <span
                        class="ml-1 font-weight-bold"
                        style="font-weight: 700 !important"
                        >{{ item.module_name }}</span
                      >
                    </h4>
                  </b-col>
                  <b-col md="12" class="pb-1" v-if="item.employee">
                    <b-row
                      v-for="(item, index) in JSON.parse(item.employee)"
                      :key="index"
                      class="py-2"
                    >
                      <b-col md="6" class="pb-1">
                        <b-input-group>
                          <template #prepend>
                            <b-input-group-text
                              class="title_lable"
                              style="width: 100%"
                              >{{ item.role }}
                            </b-input-group-text>
                          </template>
                          <b-form-input
                            v-model="item.name_employee"
                            disabled
                            style="width: 70%"
                          />
                        </b-input-group>
                      </b-col>
                      <b-col md="6" class="pb-1" :id="'col-web' + index">
                        <b-input-group>
                          <template #prepend>
                            <b-input-group-text
                              class="title_lable"
                              style="width: 100%"
                              >Email
                            </b-input-group-text>
                          </template>
                          <b-form-input
                            v-model="item.email"
                            disabled
                            style="width: 70%"
                          />
                        </b-input-group>
                      </b-col>
                      <b-col md="6" class="pb-1" :id="'col-web' + index">
                        <b-input-group>
                          <template #prepend>
                            <b-input-group-text
                              class="title_lable"
                              style="width: 100%"
                            >
                              Phone
                            </b-input-group-text>
                          </template>
                          <b-form-input
                            v-model="item.phone"
                            disabled
                            style="width: 70%"
                          />
                        </b-input-group>
                      </b-col>
                      <b-col md="6" class="pb-1" :id="'col-web' + index">
                        <b-input-group>
                          <template #prepend>
                            <b-input-group-text
                              class="title_lable"
                              style="width: 100%"
                              >Extension
                            </b-input-group-text>
                          </template>
                          <b-form-input
                            v-model="item.extension"
                            disabled
                            style="width: 70%"
                          />
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </template>
        </b-card>
        <b-card class="mx-2">
          <template #header>
            <b-card-title class="title-uwu text-primary">
              <strong>
                <feather-icon icon="CalendarIcon" size="18" class="mr-1" />
                ATTENTION
              </strong>
            </b-card-title>
          </template>
          <template #default>
            <b-row>
              <b-col
                v-for="(item, index) in departmentSchedule"
                :key="index"
                md="6"
                class="pb-1"
                :id="'day-' + index"
              >
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text class="title_lable" style="width: 100%">
                      {{ getNameDay(item.day) }}
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    :value="item.start_hour + ' - ' + item.end_hour"
                    type="text"
                    disabled
                    style="width: 70%"
                  />
                </b-input-group>
              </b-col>
            </b-row>
          </template>
        </b-card>
        <show-dynamic-forms
          v-if="showComponent"
          :id="2"
          :group_id="custom_form_group"
          :moduleId="moduleId"
          ref="dinamicForms"
          class="mx-2"
        />
      </validation-observer>
    </template>
    <create-department-type-modal
      v-if="showCreateDepartmentType"
      @hidden="showCreateDepartmentType = false"
      @onCreated="hideCreateDepartmentTypeModal"
    />
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapGetters } from "vuex";
import CompanyAddress from "@/views/management/views/companies/companies-module/create-company/CompanyAddress.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import departmentsServices from "../services/departments.services";
import showDynamicForms from "@/views/management/views/settings/views/dynamic_forms/components/ShowDynamicForms.vue";
import showDynamicComponents from "@/views/management/views/settings/views/dynamic_forms/components/ShowDynamicComponents.vue";
import CreateDepartmentTypeModal from "@/views/management/views/departments/createDepartments/CreateDepartmentTypeModal.vue";
export default {
  components: {
    vSelect,
    CompanyAddress,
    VueGoogleAutocomplete,
    showDynamicForms,
    CreateDepartmentTypeModal,
    showDynamicComponents,
  },
  props: {
    id_departament: {
      type: Number,
      default: null,
      required: false,
    },
  },

  data() {
    return {
      departament: {
        departamentTypeId: null,
        moduleId: null,
        dba: null,
        logo: null,
        associatedCompany: null,
        physicalAddress: null,
        physicalCity: null,
        physicalState: null,
        physicalZipCode: null,
        mailingAddress: null,
        mailingCity: null,
        mailingState: null,
        mailingZipCode: null,
        manBankAccountId: null, // multiselect
        emailAddress: null,
        mainTelephone: null,
        fax: null,
        addMaillingAddress: false,
        socialMedia: [
          {
            id: null,
            web_page: null,
            youtube_channel: null,
            facebook: null,
            instagram: null,
            tiktok: null,
            others: [],
          },
        ],
        members: [],
      },
      departamentTypes: [],
      nameModules: [],
      manBankAccountOptions: [],
      associatedCompanyOptions: [],
      previewImage: null,
      accountNumber: "",
      fileToUpload: null,
      optionsUSers: [],
      custom_form_id: null,
      showComponent: false,
      custom_form_group: null,
      showCreateDepartmentType: false,
      departmentSchedule: [],
      logo: null,
    };
  },
  watch: {
    "departament.manBankAccountId"(newVal, oldVal) {
      if (newVal) {
        this.accountNumber = this.manBankAccountOptions.filter(
          (bank) => bank.id == newVal
        )[0].account;
      }
    },
    "departament.addMaillingAddress"(newVal, oldVal) {
      if (newVal) {
        this.departament.mailingAddress = this.departament.physicalAddress;
        this.departament.mailingCity = this.departament.physicalCity;
        this.departament.mailingState = this.departament.physicalState;
        this.departament.mailingZipCode = this.departament.physicalZipCode;
      } else {
        this.departament.mailingAddress = "";
        this.departament.mailingCity = "";
        this.departament.mailingState = "";
        this.departament.mailingZipCode = "";
      }
    },
    "departament.associatedCompany"(newVal, oldVal) {
      if (newVal) {
        this.departament.manBankAccountId = "";
        this.getManBankAccounts();
      }
    },
    "departament.moduleId"(newVal, oldVal) {
      if (newVal) {
        this.getUserRoleByModuleId();
        this.getMember();
        this.getDepartmentSchedule();
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      skin: "appConfig/skin",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {},
  async mounted() {
    await this.getManDepartmentType();
    await this.getAllWorkAreas();
    await this.getCompanies();
    if (this.id_departament != null) {
      await this.getManDepartmentById();
    }
    await this.showComponentCustomForm();
  },
  methods: {
    async fileFill(event) {
      // imagen, pdf
      const accept = ["image/png", "image/jpg", "image/jpeg"];
      const image = event.srcElement.files[0];
      //console.log(image);

      if (image) {
        if (!accept.includes(image.type)) {
          this.showInfoSwal("Only image Allowed", "Warning");
          this.fileToUpload = [];
        } else {
          this.departament.logo = image;
          const reader = new FileReader();
          reader.onload = (e) => {
            this.previewImage = e.target.result;
          };
          reader.readAsDataURL(image);
        }
      }
    },
    getAddressData1(address_principal1) {
      const mainAddress = address_principal1.street_number
        ? `${address_principal1.street_number} ${address_principal1.route}`
        : address_principal1.route;
      this.departament.physicalAddress = mainAddress || " ";
      this.departament.physicalCity = address_principal1.locality
        ? address_principal1.locality
        : " ";
      this.departament.physicalState =
        address_principal1.administrative_area_level_1
          ? address_principal1.administrative_area_level_1
          : "";
      this.departament.physicalZipCode = address_principal1.postal_code
        ? address_principal1.postal_code
        : "";
    },
    captureChanged1(mailingAddress) {
      this.departament.physicalAddress = mailingAddress;
    },
    getAddressData2(address_principal1) {
      const mainAddress = address_principal1.street_number
        ? `${address_principal1.street_number} ${address_principal1.route}`
        : address_principal1.route;
      this.departament.mailingAddress = mainAddress || " ";
      this.departament.mailingCity = address_principal1.locality
        ? address_principal1.locality
        : " ";
      this.departament.mailingState =
        address_principal1.administrative_area_level_1
          ? address_principal1.administrative_area_level_1
          : "";
      this.departament.mailingZipCode = address_principal1.postal_code
        ? address_principal1.postal_code
        : "";
    },
    captureChanged2(mailingAddress) {
      this.departament.physicalAddress = mailingAddress;
    },
    deleteOtherSocialMedia(index) {
      this.departament.socialMedia[0].others.splice(index, 1);
    },
    deleteMember(index) {
      this.departament.members.splice(index, 1);
    },
    async getManBankAccounts() {
      const params = {
        company_id: this.departament.associatedCompany,
      };
      const { data } = await departmentsServices.getManBankAccounts(params);
      this.manBankAccountOptions = data;
    },
    async getManDepartmentType() {
      const { data } = await departmentsServices.getManDepartmentType();
      this.departamentTypes = data;
    },
    async getAllWorkAreas() {
      const { data } = await departmentsServices.getAllWorkAreas();
      this.nameModules = data;
    },
    async getCompanies() {
      const data = await departmentsServices.getCompanies();
      this.associatedCompanyOptions = data;
    },
    async getUserRoleByModuleId() {
      if (this.departament.moduleId) {
        const params = {
          module_id: this.departament.moduleId,
        };
        const data = await departmentsServices.getUserRoleByModuleId(params);
        this.optionsUSers = data;
      } else {
        return [];
      }
    },
    async createDepartments() {
      try {
        const result = await this.$refs.form.validate();
        if (!result) return;
        this.addPreloader();
        const departmentInformationsCustomForms =
          this.$refs.departmentInformationsCustomForms.$data.sections;
        const departmentSocialMediaCustomForms =
          this.$refs.departmentSocialMediaCustomForms.$data.sections;
        const dataCustomForm = this.$refs.dinamicForms.$data.sections;
        dataCustomForm.push(
          departmentInformationsCustomForms[0],
          departmentSocialMediaCustomForms[0]
        );

        const formData = new FormData();
        formData.append("files", this.departament.logo);

        formData.append(
          "department_type_id",
          this.departament.departamentTypeId
        );
        formData.append("module_id", this.departament.moduleId);
        formData.append("dba", this.departament.dba);
        formData.append("company_id", this.departament.associatedCompany);
        if (this.departament.physicalAddress) {
          formData.append(
            "physical_address",
            this.departament.physicalAddress ?? null
          );
          formData.append(
            "physical_city",
            this.departament.physicalCity ?? null
          );
          formData.append(
            "physical_state",
            this.departament.physicalState ?? null
          );
          formData.append(
            "physical_zip_code",
            this.departament.physicalZipCode ?? null
          );
        }
        if (this.departament.addMaillingAddress) {
          formData.append(
            "mailing_address",
            this.departament.mailingAddress ?? null
          );
          formData.append("mailing_city", this.departament.mailingCity ?? null);
          formData.append(
            "mailing_state",
            this.departament.mailingState ?? null
          );
          formData.append(
            "mailing_zip_code",
            this.departament.mailingZipCode ?? null
          );
        }
        formData.append("bank_accounts_id", this.departament.manBankAccountId);
        formData.append("email_address", this.departament.emailAddress);
        formData.append("main_telephone", this.departament.mainTelephone);
        if (this.departament.fax) {
          formData.append("fax", this.departament.fax);
        }
        formData.append(
          "social_media",
          JSON.stringify(this.departament.socialMedia)
        );
        formData.append(
          "member_information",
          JSON.stringify(this.departament.members)
        );
        formData.append("created_by", this.currentUser.user_id);
        formData.append("data_custom_form", JSON.stringify(dataCustomForm));
        if (this.custom_form_group) {
          formData.append("group", this.custom_form_group);
        }
        const { status } = await departmentsServices.saveManDepartment(
          formData
        );
        this.$emit("closeSidebar");
        this.$emit("refreshTable");
        this.showSuccessSwal();
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
    async getManDepartmentById() {
      const params = {
        form_id: this.id_departament,
      };
      const { data } = await departmentsServices.getManDepartmentById(params);
      this.departament.associatedCompany = data[0].company_id;
      await this.getManBankAccounts();
      await this.getDepartmentSchedule();
      this.departament.departamentTypeId = data[0].department_type_id;
      this.departament.moduleId = data[0].module_id;

      this.departament.dba = data[0].dba;
      this.departament.logo = data[0].route_logo;
      this.logo = data[0].logo;
      this.departament.physicalAddress = data[0].physical_address;
      this.departament.physicalCity = data[0].physical_city;
      this.departament.physicalState = data[0].physical_state;
      this.departament.physicalZipCode = data[0].physical_zip_code;
      this.departament.mailingAddress = data[0].mailing_address;
      this.departament.mailingCity = data[0].mailing_city;
      this.departament.mailingState = data[0].mailing_state;
      this.departament.mailingZipCode = data[0].mailing_zip_code;

      this.departament.emailAddress = data[0].email_address;
      this.departament.mainTelephone = data[0].main_telephone;
      this.departament.fax = data[0].fax;
      if (JSON.parse(data[0].social_media)) {
        this.departament.socialMedia[0].tiktok =
          JSON.parse(data[0].social_media)[0].tiktok ?? null;
        this.departament.socialMedia[0].facebook =
          JSON.parse(data[0].social_media)[0].facebook ?? null;
        this.departament.socialMedia[0].web_page =
          JSON.parse(data[0].social_media)[0].web_page ?? null;
        this.departament.socialMedia[0].instagram =
          JSON.parse(data[0].social_media)[0].instagram ?? null;
        this.departament.socialMedia[0].youtube_channel =
          JSON.parse(data[0].social_media)[0].youtube_channel ?? null;
        this.departament.socialMedia[0].others =
          JSON.parse(data[0].social_media)[0].others ?? [];
      }

      this.departament.members = JSON.parse(data[0].member_information) ?? [];
      this.departament.manBankAccountId = data[0].bank_accounts_id;
      this.custom_form_id = data[0].custom_form_id;
      this.custom_form_group = data[0].custom_form_group;
    },
    async updateDepartments() {
      try {
        const result = await this.$refs.form.validate();
        if (!result) return;
        this.addPreloader();
        const departmentInformationsCustomForms =
          this.$refs.departmentInformationsCustomForms.$data.sections;
        const departmentSocialMediaCustomForms =
          this.$refs.departmentSocialMediaCustomForms.$data.sections;
        const dataCustomForm = this.$refs.dinamicForms.$data.sections;
        dataCustomForm.push(
          departmentInformationsCustomForms[0],
          departmentSocialMediaCustomForms[0]
        );
        const formData = new FormData();
        formData.append("files", this.departament.logo);
        formData.append("logo", this.logo);

        formData.append("department_id", this.id_departament);

        formData.append(
          "department_type_id",
          this.departament.departamentTypeId
        );
        formData.append("module_id", this.departament.moduleId);
        formData.append("dba", this.departament.dba);
        formData.append("company_id", this.departament.associatedCompany);
        formData.append("department_id", this.id_departament);
        if (this.departament.physicalAddress) {
          formData.append(
            "physical_address",
            this.departament.physicalAddress ?? null
          );
          formData.append(
            "physical_city",
            this.departament.physicalCity ?? null
          );
          formData.append(
            "physical_state",
            this.departament.physicalState ?? null
          );
          formData.append(
            "physical_zip_code",
            this.departament.physicalZipCode ?? null
          );
        }
        if (this.departament.addMaillingAddress) {
          formData.append(
            "mailing_address",
            this.departament.mailingAddress ?? null
          );
          formData.append("mailing_city", this.departament.mailingCity ?? null);
          formData.append(
            "mailing_state",
            this.departament.mailingState ?? null
          );
          formData.append(
            "mailing_zip_code",
            this.departament.mailingZipCode ?? null
          );
        }
        formData.append("bank_accounts_id", this.departament.manBankAccountId);
        formData.append("email_address", this.departament.emailAddress);
        formData.append("main_telephone", this.departament.mainTelephone);
        if (this.departament.fax) {
          formData.append("fax", this.departament.fax);
        }
        formData.append(
          "social_media",
          JSON.stringify(this.departament.socialMedia)
        );
        formData.append(
          "member_information",
          JSON.stringify(this.departament.members)
        );
        formData.append("created_by", this.currentUser.user_id);
        formData.append("data_custom_form", JSON.stringify(dataCustomForm));
        formData.append("group", this.custom_form_group);
        const { status } = await departmentsServices.updateManDepartmentById(
          formData
        );

        this.$emit("closeSidebar");
        this.$emit("refreshTable");
        this.showSuccessSwal();
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
    addTypeDepartment() {
      this.newTypeDepartment = true;
    },
    async showComponentCustomForm() {
      this.showComponent = true;
    },
    showCreateDepartmentTypeModal() {
      this.showCreateDepartmentType = true;
    },
    hideCreateDepartmentTypeModal() {
      this.showCreateDepartmentType = false;
      this.getManDepartmentType();
    },
    getSocialMedia() {
      return this.departament.socialMedia.filter((item) => {
        return Object.values(item).some(
          (val) => val !== null || val.trim() === ""
        );
      });
    },
    async getMember() {
      if (this.departament.moduleId) {
        const params = {
          id_module: this.departament.moduleId,
        };
        const { data } = await departmentsServices.getMemberModuleId(params);
        this.departament.members = data;
      } else {
        return [];
      }
    },
    fillDataMember(index, id) {
      const memberObject = this.optionsUSers.filter((val) => val.id == id)[0];
      this.departament.members[index].email_address =
        memberObject.email_address;
      this.departament.members[index].telephone = memberObject.telephone;
      this.departament.members[index].extension = memberObject.extension;
    },
    async getDepartmentSchedule() {
      const params = {
        department_id: this.departament.moduleId,
      };
      const { data } = await departmentsServices.getDepartmentSchedule(params);
      this.departmentSchedule = data;
    },
    getNameDay(day) {
      switch (day) {
        case 1:
          return "MONDAY";
          break;
        case 2:
          return "TUESDAY";
          break;
        case 3:
          return "WEDNESDAY";
          break;
        case 4:
          return "THURSDAY";
          break;
        case 5:
          return "FRIDAY";
          break;
        case 6:
          return "SATURDAY";
          break;
        case 7:
          return "SUNDAY";
          break;
      }
    },
    addOtherSocialMedia() {
      this.departament.socialMedia[0].others.push({ name: "", value: "" });
    },
    deleteOther(index) {
      this.departament.socialMedia[0].others.splice(index, 1);
    },
    deleteLogo() {
      this.departament.logo = null;
      this.previewImage = null;
      this.$refs.file.value = "";
      this.logo = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.management-departament {
  .input-group-prepend {
    width: 30% !important;
  }

  .blink {
    animation: animationBlink 1s ease-in infinite;
  }
  .blink:hover {
    animation: none;
  }
  @keyframes animationBlink {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  .selectLogo {
    position: relative;
  }

  .message_logo {
    background: rgba($color: #000000, $alpha: 1);
    height: 50px;
    font-size: 20px;
    opacity: 0;
    position: absolute;
    color: #fff !important;
  }

  .selectLogo:hover {
    //opacity: 0.5;
    .message_logo {
      opacity: 1;
      bottom: 10px;
      padding: 10px 20px;
      width: 100%;
    }
  }
}
</style>
