export default[
    {
        key: 'name',
        sortable: true,
        label: 'Type',
        visible: true,
    },
    {
        key: 'name_module',
        sortable: true,
        label: 'Name',
        visible: true,
    },
    {
        key: 'dba',
        sortable: true,
        label: 'DBA',
        visible: true,
    }, 
    {
        key: 'company_id',
        sortable: true,
        label: 'Company',
        visible: true,
    },
    {
        key: 'physical_address',
        sortable: true,
        label: 'Address',
        visible: true,
    },
    {
        key: 'email_address',
        sortable: true,
        label: 'Mailing Address',
        visible: true,
    },
    {
        key: 'main_telephone',
        sortable: true,
        label: 'Phone',
        visible: true,
    },
    {
        key: 'actions',
        sortable: true,
        label: 'Actions',
        visible: true,
    }
]