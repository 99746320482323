import { amgApi } from '@/service/axios'

// General services on module MetaMedia after refactoring
class DepartmentsService {
  async searchDepartment(params) {
    try {
      const { data, status } = await amgApi.post(
        "management/man-department/search-grid-department",
        params
      );
      return { data, status };
    } catch (error) {
      throw error;
    }
  }
  async getManBankAccounts(params) {
    try {
      const { data, status } = await amgApi.post(
        "management/man-department/get-man-bank-accounts",
        params
      );
      return { data, status };
    } catch (error) {
      throw error;
    }
  }
  async getManDepartmentById(params) {
    try {
      const { data, status } = await amgApi.post(
        "management/man-department/get-man-department-by-id",
        params
      );
      return { data, status };
    } catch (error) {
      throw error;
    }
  }
  async updateManDepartmentById(params) {
    try {
      const { data, status } = await amgApi.post(
        "management/man-department/update-man-department-by-id",
        params
      );
      return { data, status };
    } catch (error) {
      throw error;
    }
  }
  async saveManDepartmentType(params) {
    try {
      const { data, status } = await amgApi.post(
        "management/man-department/save-man-department-type",
        params
      );
      return { data, status };
    } catch (error) {
      throw error;
    }
  }
  async getManDepartmentType() {
    try {
      const { data, status } = await amgApi.get(
        "management/man-department/get-man-department-type"
      );
      return { data, status };
    } catch (error) {
      throw error;
    }
  }
  async saveManDepartment(params) {
    try {
      const { data, status } = await amgApi.post(
        "management/man-department/save-man-department",
        params
      );
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getModules() {
    try {
      const { data } = await amgApi.get('module/get-modules');
      return data
    } catch (error) {
      console.log(error)
    }
  }

  async getCompanies() {
    try {
      const { data } = await amgApi.get('management/man-department/get-all-companies');
      return data
    } catch (error) {
      console.log(error)
    }
  }

  async getUserRoleByModuleId(params) {
    try {
      const { data } = await amgApi.post(
        'management/man-department/get-users-by-role-id-and-module-id',
        params,
      )
      return data
    } catch (error) {
      throw error
    }
  }


  async deleteManDepartment(params) {
    try {
      const { data, status } = await amgApi.post(
        "management/man-department/delete-man-department",
        params
      );
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getMemberModuleId(params) {
    try {
      const { data, status } = await amgApi.post(
        "management/man-department/get-member-module-by-id",
        params
      );
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getDepartmentSchedule(params) {
    try {
      const { data, status } = await amgApi.post(
        "management/man-department/get-department-schedule",
        params
      );
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getAllWorkAreas() {
    try {
      const { data, status } = await amgApi.get(
        "management/man-department/get-all-work-areas"
      );
      return { data, status };
    } catch (error) {
      throw error;
    }
  }
}
export default new DepartmentsService();
