<template>
  <div>
    <header-slot>
      <template #actions>
        <b-button
          variant="success"
          class="mr-1"
          @click="addUpdateDepartaments(null, 1)"
          ><feather-icon
            icon="PlusIcon"
            size="15"
            class="mr-50 text-white"
          />CREATE
        </b-button>
      </template>
    </header-slot>

    <b-card no-body class="mb-2">
      <filter-slot
        :filter="filter"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reset-all-filters="resetAllFilters"
        @reload="$refs['DepartamentsList'].refresh()"
      >
        <b-table
          id="DepartamentsList"
          slot="table"
          ref="DepartamentsList"
          class="m-1"
          sticky-header="70vh"
          no-provider-filtering
          :busy.sync="isBusy"
          :items="myProvider"
          :fields="visibleFields"
          :per-page="paginate.perPage"
          :current-page="paginate.currentPage"
          :filter="filter"
          show-empty
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template v-slot:cell(name)="data">
            {{ data.item.name }}
          </template>
          <template v-slot:cell(name_module)="data">
            {{ data.item.name_module }}
          </template>
          <template v-slot:cell(company_id)="data">
            {{
              data.item.company_name
                ? data.item.company_name
                : data.item.company_dba
            }}
          </template>
          <template v-slot:cell(actions)="data">
            <feather-icon
              icon="Edit2Icon"
              size="15"
              class="text-warning cursor-pointer mr-1"
              v-b-tooltip.hover.bottom="'Update'"
              @click="addUpdateDepartaments(data.item, 2)"
            />
            <feather-icon
              icon="TrashIcon"
              size="15"
              class="text-danger cursor-pointer"
              v-b-tooltip.hover.bottom="'Delete'"
              @click="deleteDepartment(data.item.id)"
            />
          </template>
        </b-table>
      </filter-slot>
    </b-card>

    <b-sidebar
      id="sidebar-right"
      v-model="sidebarState"
      :bg-variant="isDarkSkin ? 'dark' : 'white'"
      :title="titleSidebar + ' DEPARTMENT ' + nameDepartment"
      right
      width="1300px"
    >
      <create-departament
        v-if="sidebarState"
        :id_departament="id_departament"
        @closeSidebar="closeSidebar"
        @refreshTable="resetSearch"
        ref="departmentForm"
      />
      <template #footer>
        <b-card class="mb-0">
          <template #default>
            <div class="d-flex justify-content-end">
              <b-button
                class="mr-1"
                variant="outline-secondary"
                @click="closeSidebar"
              >
                Cancel
              </b-button>
              <b-button variant="primary" @click="saveDepartment()">
                {{ id_departament === null ? "Save" : "Update" }}
              </b-button>
            </div>
          </template>
        </b-card>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Fields from "@/views/management/views/departments/data/departments.fields.js";
// import CompaniesService from '@/views/management/views/companies/companies.service';
import DepartamentsService from "@/views/management/views/departments/services/departments.services.js";
import createDepartament from "@/views/management/views/departments/createDepartments/createDepartament.vue";

export default {
  components: {
    createDepartament,
  },

  data() {
    return {
      fields: Fields,
      totalRows: 0,
      sidebarState: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      isBusy: false,
      basicSearch: true,
      filter: [],
      startPage: null,
      toPage: null,
      items: [],
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      statusLead: [],
      company: {
        another_address: null,
        category: "BUSINESS",
        address: null,
        city: null,
        country: "US",
        direct_number: null,
        display_name_as: null,
        ein_tax_id: null,
        email: null,
        extension: null,
        mailling_address: null,
        mailling_city: null,
        mailling_state: null,
        mailling_zip_code: null,
        main_number: null,
        name: null,
        other_number: null,
        ruc: null,
        state: null,
        toll_free: null,
        use_main_address: null,
        zip_code: null,
        // Payroll
        cts: true,
        bonuses: true,
        payroll: true,
        regimen: null,
        // IMAGEN UPLOAD
        image: "",
        fileImg: "",
        extension2: "",
      },
      titleSidebar: "",
      id_departament: null,
      operation: null,
      nameDepartment: "",
    };
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    resetAllFilters() {
      this.filter.forEach((filter) => {
        // eslint-disable-next-line no-param-reassign
        filter.model = null;
      });
      this.filterPrincipal.model = null;
      this.$refs.DepartamentsList.refresh();
    },
    resetSearch() {
      this.searchInput = "";
      this.$refs.DepartamentsList.refresh();
    },

    addUpdateDepartaments(item, operation) {
      this.id_departament = item ? item.id : null;
      this.operation = operation;
      this.nameDepartment = item ? item.name_module.toUpperCase() : "";
      this.titleSidebar = operation === 1 ? "ADD" : "UPDATE";
      this.sidebarState = true;
    },

    closeSidebar() {
      this.sidebarState = false;
      this.selectedCompany = null;
    },
    async myProvider(ctx) {
      let order = ctx.sortDesc ? "asc" : "desc";
      let order_by = ctx.sortBy ? ctx.sortBy : "id";
      order = ctx.sortBy ? order : "desc";
      try {
        const params = {
          search: this.filterPrincipal.model,
          module_id: this.moduleId,
          order_by: order_by,
          orden: order,
          per_page: this.paginate.perPage,
          page: ctx.currentPage,
          from: null,
          to: null,
        };
        const data = await DepartamentsService.searchDepartment(params);
        this.items = data.data.data;
        this.startPage = data.data.from;
        this.paginate.currentPage = data.data.current_page;
        this.paginate.perPage = data.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.data.last_page;
        this.totalData = data.data.total;
        this.totalRows = data.data.total;
        this.toPage = data.data.to; 
        return this.items;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    saveDepartment() {
      if (this.id_departament === null) {
        this.$refs.departmentForm.createDepartments();
      } else {
        this.$refs.departmentForm.updateDepartments();
      }
    },
    async deleteDepartment(id) {
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          const params = {
            id: id,
            user_id: this.currentUser.user_id,
          };
          const data = await DepartamentsService.deleteManDepartment(params);
          this.$refs.DepartamentsList.refresh();
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
  },
};
</script>

<style scoped>
.elipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
}
</style>