<template>
  <b-modal
    ref="create-department-type-modal"
    @hidden="$emit('hidden')"
    centered
    size="sm"
  >
    <!-- TITLE MODAL -->
    <template #modal-title>
      <span class="font-weight-bolder"> CREATE DEPARTMENT TYPE </span>
    </template>
    <!-- BODY MODAL -->
    <template #default>
      <validation-observer ref="form">
        <div class="pt-2 mb-2">
          <b-row class="mb-2">
            <b-col md="12">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-group label="Name">
                  <b-form-input
                    v-model="departmentType.name"
                    :class="errors[0] ? 'is-invalid' : 'is-valid'"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </div>
      </validation-observer>
    </template>
    <template #modal-footer>
      <b-row class="d-flex justify-content-end">
        <b-button variant="outline-success" @click="createDepartmentType()">
          CREATE
          <feather-icon icon="SaveIcon" />
        </b-button>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import DepartmentsServices from "../services/departments.services";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      departmentType: {
        name: null,
        description: null,
        created_by: null,
      },
    };
  },
  mounted() {
    this.toggleModal("create-department-type-modal");
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    async createDepartmentType() {
      try {
        this.departmentType.created_by = this.currentUser.user_id;
        const valid = await this.$refs["form"].validate();
        if (!valid) return;
        await DepartmentsServices.saveManDepartmentType(this.departmentType);
        this.showSuccessSwal("Department Type created successfully");
        this.$emit("onCreated");
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>
